.shop-container {
  display: flex;
  flex-direction: column;

  .products-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 30px;
    row-gap: 40px;
    justify-items: center;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1300px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1600px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  .section-name {
    font-size: 30px;
    font-weight: bold;
    height: 80px;
  }
}
