.burger-menu {
  display: flex;
  height: 100vh;
  flex-direction: row;
  flex-flow: row;

  .opacity {
    background-color: black;
    opacity: 0.3;
    flex: 1 1 auto;
    margin-top: 160px;
  }

  .menu-container {
    height: 100%;
    width: 300px;
    background-color: #3d3d3d;
    overflow: scroll;

    .spacer {
      height: 120px;
    }

    .burger {
      width: 100%;
      height: 64px;
      padding-left: 20px;
      background-color: black;
      align-items: center;
      display: flex;
      margin-bottom: 10px;

      .burger-icon {
        height: 50px;
      }
    }

    .nav-links {
      display: flex;
      flex-direction: column;

      .nav-link {
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        width: 100%;
        font-size: 15px;
        font-weight: bold;
        padding: 10px 30px;
        width: fit-content;
      }
    }

    .navigation-item-container {
      position: relative;
      cursor: pointer;
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      width: 100%;
      flex-direction: column;

      .navigation-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .navigation-key {
          font-size: 15px;
          font-weight: bold;
          padding: 10px 10px;
          width: fit-content;

          .nav-link {
            color: white;
          }
        }

        .arrow-container {
          padding-left: 20px;
          width: 20%;
          .dropdown-arrow {
            transform: rotate(90deg);
            padding: 10px;
          }
        }
      }

      .dropdown-content {
        display: none;
        position: relative;
        width: 100%;
        z-index: 1;

        .dropdown-item {
          color: white;
          padding: 12px 0px 12px 20px;
          text-decoration: none;
          display: block;
        }
      }

      .dropdown-item:hover {
        background-color: #ddd;
      }
    }

    .navigation-item-container:hover .arrow-container {
      padding: 0 10px 0 0;
      transform: rotate(180deg);
    }

    .navigation-item-container:hover {
      background-color: #2b2a2a;
    }

    .navigation-item-container:hover .dropdown-content {
      display: block;
    }
  }

  @media (min-width: 1024px) {
    .menu-container {
      display: none;
    }
  }
}
