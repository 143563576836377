.checkout-item {
  max-width: 800px;
  width: 97%; 
  display: flex;
  height: 150px;
  border-bottom: 1px solid darkgrey; 
  margin: 10px; 
  padding: 10px;
  font-size: 16px; 
  align-items: center;
  box-sizing: border-box; /* Ensures padding and border are included in width */
}

.checkout-item-image-container {
  width: 150px;
  height: 100%;
}

.properties {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .main-properties {
    height: 35px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .name {
      width: 60%;
      text-align: left;
      padding-left: 20px;
      font-weight: bold;
    }

    .quantity-remove-container {
      display: flex;
      align-items: center;
    }
  }

  .secondary-properties {
    height: 100%;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;

    .props {
      width: 60%;
      text-align: left;
    }

    .quantity-price-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .item-price {
        text-align: end;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
}

.checkout-item-image-container img {
  // width: 100%;
  height: 100%;
  object-fit: fill; /* Ensures the image covers the container */
}

.name,
.props,
.quantity {
  width: 20%;
  text-align: center; /* Center aligns text */
}

.remove-button {
  width: 10%;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
}

