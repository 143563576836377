

.checkout-page-container {
    display: flex;
    flex-direction: column;

    .items-container {
        // width: 100%;

        .items-list {
            list-style: none;
            max-height: 600px;
            padding: 0;
            overflow-y: scroll;
        }

        .total-price {
            text-align: end;
            padding-right: 30px;
            font-size: 25px;
            font-weight: bold;
            color: red;
            text-decoration: line-through;
        }

        .reduced-price {
            text-align: end;
            padding-right: 30px;
            font-size: 25px;
            font-weight: bold;
        }

        #items-list::-webkit-scrollbar {
            width: 12px;
          }
  
          #items-list::-webkit-scrollbar-track50 {
            border-radius: 8px;
            background-color: #e7e7e7;
            border: 1px solid #cacaca;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          }
  
          #items-list::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: #363636;
          }
    }

    .user-info {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width:1024px) {
    .checkout-page-container {
        flex-direction: row;

        .items-container {
            padding-right: 50px;
            width: 50%;
        }

        .user-info {
            width: 50%;
        }
    }
}

.hidden {
    display: none;
  }
