.color-box-container {
  display: flex;
  flex-direction: row;
  min-height: 22px;

  .color-box {
    align-items: center;

    .color-box-label {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin: 0px 2px;
      cursor: pointer;
    }
  
    
  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + .color-box-label {
    scale: 1.1;
    border: 2px solid black;
  }
  }

 
}
