.radio-component-container {
  display: flex;
  padding: 10px 0px;

  .radio-label {
    // padding: 0px 10px;
    //  grid-column: span 1;
    grid-row: span 1;
    padding-top: 3px;
    width: 120px;
    // height: 30px;
    // align-content: center;
  }

  .radio-container {
    display: flex;
    flex-wrap: wrap;
    // grid-template-columns: 23% 23% 23% 23% ;
    // grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    row-gap: 10px; 
    list-style: none;
    width: 80%;
    padding: 0px;
    margin: 0px;
  
    
  
    .radio-button-container {
      min-width: 17%;
      height: 30px;
      border-radius: 5px;
      border: 1px solid black;
      margin: 0 5px;
  
      input[type="radio"] {
        display: none;
      }
  
      .radio-button-label {
        display: block;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        cursor: pointer;
        background-color: #e4dddd;
        border-radius: 4px;
      }
  
      input[type="radio"]:checked + .radio-button-label {
        background-color: #252525;
        color: white;
        border-radius: 0px;
      }
    }
  }
  
}

