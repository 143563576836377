.search-box-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100%;
  // position: absolute;

  .box-icon-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    display: flex;

    .search-box {
      appearance: none;
      border: none;
      outline: none;
      line-height: 30px;
      width: 100%;
      height: 40px;
      background-color: white;
      border-radius: 50px;
      font-size: 15px;
      padding: 0 50px 0 20px;
    }

    .search-icon {
      padding: 0 15px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
      right: 0;
      position: absolute;
    }
  }
}

@media (min-width: 1024px) {
  .search-box-container {
    height: 50px;
    max-width: 800px;
    justify-content: end;
    padding: 0 10px;

    .box-icon-container {
      height: 50px;

      .search-box {
        line-height: 20px;
        height: 50px;
        max-width: 800px;
      }

      .search-icon {
        padding: 0 15px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        position: absolute;
      }
    }
  }
}
