body,
html {
  height: 100%;
}

.page-container {
  width: 100%;
  position: relative;

  .sticky-navigation {
    position: sticky;
    top: 0;
    z-index: 100;

    .navigations-container {
      position: relative;
      .mobile-navigation-container {
        position: absolute;
        top: 0;
        z-index: 91;
        width: 100%;
      }

      @media (min-width: 1024px) {
        .mobile-navigation-container {
          display: none;
        }
      }

      .navigation-container {
        position: absolute;
        top: 0;
        z-index: 98;
        width: 100%;
        background-color: #3d3d3d;
        height: 120px;
        @media (min-width: 1024px) {
          height: 160px;
        }
        display: flex;
        flex-flow: column;

        .navigation {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .center-middle {
            justify-content: center;
          }

          .logo-title-container {
            height: 60px;
            width: 100%;
            align-items: center;
            display: flex;

            .logo-title-link {
              height: 60%;
              width: fit-content;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }

            .logo-container {
              width: 70px;
              padding: 10px;
            }

            .title {
              font-family: "Playwrite MX";
              color: white;
              font-size: 25px;
            }
          }

          .nav-links-container {
            width: 100%;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: black;
            padding: 0 20px;

            .burger {
              width: 50px;
            }

            .checkout {
              height: 50px;
              border-radius: 30px;
              padding: 5px;
              background-color: #3d3d3d;
            }

            .header-links {
              display: flex;
              flex-direction: row;
              width: 100%;
              position: relative;

              .search-box-items-container {
                display: flex;
                flex-direction: column;
                width: 100%;
                position: absolute;
                top: -20px;

                .filtered-items-list {
                  top: 7px;
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 0 30px;
                  z-index: 100;

                  .remove-button {
                    width: 100%;
                    max-width: 500px;
                    min-width: 300px;
                    text-align: end;
                    padding-right: 10px;
                    cursor: pointer;
                    font-size: 24px;
                    background-color: white;
                    border: 1px solid black;
                  }
                }
              }

              .nav-links {
                display: none;

                .nav-link {
                  padding: 10px 15px;
                  cursor: pointer;
                  color: white;
                }
              }
            }
          }
        }

        .navigation-pages {
          display: none;
        }

        @media (min-width: 1024px) {
          .navigation {
            height: 90px;
            flex-direction: row;
            padding: 0 20px;

            .logo-title-container {
              height: 100%;
              width: 50%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              padding: 10px 0;

              .logo-title-link .title {
                font-family: "Playwrite MX";
                color: white;
                font-size: 30px;
              }
            }

            .nav-links-container {
              width: 50%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background-color: transparent;

              .burger {
                display: none;
              }

              .checkout {
                height: 50px;
                border: 1px solid black;
                border-radius: 30px;
                padding: 5px;
                background-color: black;
              }

              .header-links {
                justify-content: right;

                .search-box-items-container {
                  top: -25px;

                  .filtered-items-list {
                    max-width: 800px;
                    padding: 0 10px;
                    align-items: end;
                  }
                }

                .nav-links {
                  display: flex;

                  .nav-link {
                    padding: 10px 15px;
                    cursor: pointer;
                    color: white;
                  }
                }
              }
            }
          }

          .navigation-pages {
            display: flex;
            padding: 0 10px;
            width: 100%;
            background-color: black;
            flex: 1 1 auto;
            flex-flow: row;

            .navigation-item-container {
              // position: absolute;
              margin: 0 15px 0 0;
              cursor: pointer;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              width: min-content;
              flex: 1 1 auto;

              .navigation-item {
                display: flex;
                align-items: center;
                justify-content: center;

                .navigation-key {
                  font-size: 15px;
                  font-weight: bold;
                  padding: 10px 10px;

                  .nav-link {
                    color: white;
                  }
                }

                .arrow-container {
                  .dropdown-arrow {
                    padding: 10px;
                    transform: rotate(90deg);
                  }
                }
              }

              .dropdown-content {
                display: none;
                position: absolute;
                background-color: white;
                min-width: 160px;
                z-index: 1;
                top: 160px;
                box-shadow: 0px 4px 12px 1px rgba(145, 143, 143, 0.5);

                .dropdown-item {
                  color: black;
                  padding: 12px 16px;
                  text-decoration: none;
                  display: block;
                }
              }

              .dropdown-item:hover {
                background-color: #ddd;
              }
            }

            @media (min-width: 1130px) {
              .navigation-item-container {
                width: auto;
              }
            }

            .navigation-item-container:hover .arrow-container {
              transform: rotate(180deg);
            }

            .navigation-item-container:hover .dropdown-content {
              display: block;
            }
          }
        }
      }
    }
  }

  .cc-container {
    position: relative;
    top: 120px;

    .content-container {
      position: relative;
      min-height: calc(100vh - 120px);

      .background-container {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
      }

      .discount {
        background-color: rgb(101, 207, 124);
        width: 100%;
        vertical-align: middle;
        position: sticky;
        top: 120px;
        z-index: 90;
        display: flex;
        font-weight: bold;

        .discount-text {
          width: 100%;

          text-align: center;
          font-size: 40px;
          padding: 20px 0px 20px 30px;
        }

        .discount-close {
          padding-top: 5px;
          width: 30px;
          height: 100%;
          cursor: pointer;
        }
      }

      .hidden {
        display: none;
      }

      .discount-home {
        position: sticky;
        top: 0px;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .outlet {
          min-height: 100vh;

          @media (max-width: 1024px) {
            min-height: 0;
          }
          padding: 40px 40px 25px 40px;
        }
      }
    }
    @media (min-width: 1024px) {
      min-height: 100vh;
      top: 160px;

      .content-container {
        position: relative;
        min-height: calc(100vh - 160px);
        background-container {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
        }
    
        .discount {
          top: 160px;
        }
        
        .discount-home {
          position: sticky;
          top: 0px;
        }
      }
    }
  }
}

.home {
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-image: url(../../../assets/home-page.JPG);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.home-footer {
  position: absolute;
}

@media (max-width: 1024px) {
  .main-footer-container {
    display: none;      
  }
}

@media (min-width: 1024px) {
  .main-footer-container {
    width: 100%;
    position: relative;
  }
}
