

.table-container {
  width: 100%;
  max-width: 800px;

  table {
    width: 100%;
    border-collapse: collapse;

    tbody {
      tr {
        
        &:nth-child(even) {
          background-color: #cccbcb;
        }
        &:nth-child(odd) {
          background-color: #ffffff;
        }

        td {
          padding: 12px;
          border: 1px solid black;

          &:first-child {
            font-weight: bold;
            width: 30%;
          }

          &:last-child {
            width: 70%;
          }
        }
      }
    }
  }
}
