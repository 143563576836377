.product-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 500px;
  max-width: 450px;
  align-items: center;
  position: relative;
  box-shadow: 0px 4px 6px -1px rgba(145, 143, 143, 0.5);
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  padding: 10px 20px;


  .image-container {
    width: 100%;
    // max-height: 85%;
    justify-content: center;
    align-items: center;

    img {
      object-fit: fill;
      margin-bottom: 5px;
      padding-top: 10px;
      width: 100%;
      height: 100%;
    }
    &:hover {
      img {
        opacity: 0.8;
      }

      button {
        opacity: 0.85;
        display: flex;
      }

      scale: 1.01;
    }
  }

  .name-price-color-container {
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: column;

    .color-picker-replace {
      height: 22px;
    }

    .name-price-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      padding: 0 10px 0 0;

      .name {
        margin-bottom: 15px;
        width: 100%;
        text-align: left;

      }

      .price {
        width: 100px;
        text-align: right;
        font-size: 18px;
        color: rgb(80, 79, 79);
        margin-bottom: 5px;
      }
    }
  }
}
