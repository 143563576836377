@media(max-width: 1024px) {
    .footer-container {
        width: 300px;
        height: 250px;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        color: white;
        z-index: 100;
        justify-content: center;
        align-items: center;
    
      
        .footer-info {
          height: 100%;
          width: 100%;
          text-align: center;
      
        }
        .footer-contact {
          height: 100%;
          display: flex;
      
      
          a {
              padding: 10px;
              img {
                  height: 50px;
                  width: 50px;
                }
          }
          
        }
      }
}

@media (min-width: 1024px) {

    .footer-container {
        width: 100%;
        height: 170px;
        position: relative;
        bottom: 0;
        padding-bottom: 30px;
        display: flex;
        background-color: #3d3d3d;
        color: white;
        z-index: 100;
      
        .footer-info {
          height: 100%;
          width: 100%;
          padding: 20px 0 10px 150px;
      
        }
        .footer-contact {
          height: 100%;
          width: 120px;
          padding: 20px 300px 10px 0;
          display: flex;
          justify-content: space-between;
      
      
          a {
              padding: 10px;
              img {
                  height: 50px;
                  width: 50px;
                }
          }
          
        }
      }
  }