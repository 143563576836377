.checkout-item-container {
  border: 1px lightgray solid;
  width: auto;
  display: flex;
  height: 50px;
  padding: 15px 10px 15px 0;
  font-size: 20px;
  align-items: center;

  .quantity {
    width: 23%;
  }

  .quantity {
    display: flex;

    .arrow-container {
      display: flex;
      flex-direction: column;

      .arrow-up {
        font-size: 20px;
        margin: -5px 0 0 0;
        cursor: pointer;

      }

      .arrow-down {
        font-size: 20px;
        margin: 0px 0 -5px 0;
        cursor: pointer;
      }
    }

    

    .value {
      margin: 0 10px;
    }
  }

}