.home-container {
  height: 100%;
  
  .welcome-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .welcome-speech {
      width: 90%;
      padding: 10px;
      font-weight: bold;
      font-size: 18px;
      background-color: white;
      opacity: 0.8;
    }

    @media (min-width: 1024px) {
      .welcome-speech {
        width: 50%;
      }
    }
  }
}
