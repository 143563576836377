.gallery {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;

    .image-container {
      height: 100px;
      width: 100px;
      // max-width: 100px;
      // min-width: 75px;
      // min-height: 75px;
      // max-height: 100px;
      box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      margin: 0px 10px 10px 0px;

    }
  }


  @media (min-width: 1024px) {
    .gallery {
        width: 125px;
        flex-direction: column;
        max-height: 500px;
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: auto;


        .image-container {
          width: 90px;
          height: 90px;
          overflow: visible;
        }
      }

      #gallery::-webkit-scrollbar {
        width: 12px;
      }

      #gallery::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: #e7e7e7;
        border: 1px solid #cacaca;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      #gallery::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #363636;
      }
  }