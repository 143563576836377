.search-card-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  height: 100px;
  background-color: white;
  border: 1px solid black;
  border-top: none;

  .link {
    width: 100%;
    display: flex;
    flex-direction: row;

    .product-image {
      width: 100px;
      height: 100%;

      img {
        width: 100px;
        height: 100px;
      }
    }

    .product-price {
      width: 100px;
      height: 100%;
    }

    .product-name {
      font-weight: bold;
      width: 100%;
      height: 100%;
    }
  }
}
