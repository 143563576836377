.cart-icon-container {
    width: 40px;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  
    .shopping-icon {
      width: 40px;
      height: 40px;
    }
  
    .item-count {
      position: absolute;
      font-size: 14px;
      font-weight: bold;
      bottom: 6px;
      color: white
    }
  }