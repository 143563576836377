.item-page-container {
  padding: 20px 0;
  .gallery-attributes-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .gallery-container {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      max-width: 800px;

      img {
        width: 100%;
        height: fill;
      }
    }

    .attributes-container {
      width: 100%;

      .title {
        font-size: 40px;
        text-align: center;
      }

      .desc {
        text-align: justify;
      }

      .price {
      }

      .divider-solid {
        border-top: 1px solid darkgray;
      }

      .color-picker {
        display: flex;
        align-items: center;
        padding: 10px 0px;

        .color-picker-label {
          width: 120px;
        }

        .color-picker-component {
          width: 80%;

          .color-box-container {
            display: flex;
            flex-direction: row;
            min-height: 22px;
          
            .color-box {
              align-items: center;
          
              .color-box-label {
                display: inline-block;
                width: 30px;
                height: 30px;
                margin: 0px 2px;
                cursor: pointer;
              }
            
              
            input[type="radio"] {
              display: none;
            }
          
            input[type="radio"]:checked + .color-box-label {
              scale: 1.1;
              border: 2px solid black;
            }
            }
          
           
          }
        }
      }

      .button-quantity-container {
        padding: 20px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .quantity {
          display: flex;
          align-items: center;

          .quantity-label {
            display: none;
            padding-right: 40px;
          }
        }
      }
    }
  }
}

@media (min-width: 767px) {
  .item-page-container {
    .gallery-attributes-container {
      .attributes-container {
        .button-quantity-container {
          .quantity {
            .quantity-label {
              display: block;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .item-page-container {
    padding: 20px 60px;

    .gallery-attributes-container {
      flex-direction: row;

      .gallery-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding-top: 30px;
        max-width: 500px;

        .main-image-container {
          width: 80%;
          img {
            // width: 100%;
            height: fit-content;
          }
        }
      }

      .attributes-container {
        width: 600px;

        .title {
          font-size: 40px;
          text-align: left;
        }

        .desc {
          text-align: justify;
        }

        .price {
        }

        .divider-solid {
          border-top: 1px solid darkgray;
        }

        .color-picker {
          display: flex;
          align-items: center;
          padding: 10px 0px;

          .color-picker-label {
            width: 120px;
          }

          .color-picker-component {
            width: 80%;

            .color-box-container {
              .color-box {
            
                .color-box-label {
                  width: 25px;
                  height: 25px;
                }
              }
            
             
            }
          }
        }

        .button-quantity-container {
          padding: 20px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}
